.jumbo__title {
    font-family: inherit;
    font-size: 1.875rem;
}

.jumbo__btn {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    padding: 0.7rem 0;
    min-width: 100%;

    text-align: center;
    text-decoration: none;
    color: #db1a21;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 50px;

    transition: color 0.5s;
    transform: translateZ(0);
    overflow: hidden;
    cursor: pointer;
}

.jumbo__btn::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    background: #db1a21;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.jumbo__btn::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.jumbo__btn:active {
    position: relative;
    top: 5px;
    color: #db1a21;
}

.jumbo__btn:active::after {
    -webkit-transform: scale(2);
    transform: scale(2);
}

.jumbo__btn_red {
    color: #ffffff;
    background-color: #db1a21;
    border: 2px solid #db1a21;
}

.jumbo__btn_red::before {
    background: #ffffff;
}

.jumbo__btn_red::after {
    background: #db1a21;
}

.jumbo__text {
    max-width: 428px;
    margin-top: 0;
    margin-bottom: 1.5em;

    font-size: 1em;
    font-family: 'Montserrat', sans-serif;
}

.jumbo__text_long {
    max-width: 495px;
}

@media (min-width: 768px) {
    .jumbo__btn {
        min-width: 350px;
        padding: 1.25rem;
    }

    .jumbo__btn:hover {
        color: #ffffff;
        transition: color 0.5s;
    }

    .jumbo__btn:hover::before {
        transform: scaleX(1);
    }

    .jumbo__btn_red:hover {
        color: #db1a21;
        transition: color 0.5s;
    }

    .jumbo__btn_red:active {
        color: #ffffff;
    }

    .jumbo__title {
        font-size: 3rem;
        margin-top: calc(8rem + 71px);
    }

    .jumbo__text {
        font-size: 1.2em;
    }
}
