.feedback-modal__content {
    padding: 20px;
    border-radius: 1.5rem;
}

@media (min-width: 576px) {
    .feedback-modal__dialog {
        max-width: 800px
    }
}

.file-upload-error-info {
    display: none;

    text-align: center;
    color: #dc3545;
    font-size: 0.875em;
    font-family: "Roboto Slab", serif;
}

.box {
    font-size: 1.25rem;
    position: relative;
    padding: 45px 20px;
    border-radius: 25px;
}

.box__info {
    font-size: 0.9rem;
    color: grey;
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
    display: none;
}

.box.has-advanced-upload .box__dragndrop {
    display: inline;
}


.box.has-advanced-upload
{
    outline: 3px dashed #ced4da;
    outline-offset: -3px;

    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
}

.box.has-advanced-upload.box__invalid-info {
    outline: 3px dashed #dc3545;
}

.box.has-advanced-upload.box__invalid-info ~ .file-upload-error-info {
    display: block;
}

.box.is-dragover
{
    outline-offset: -20px;
    outline-color: #c8dadf;
    background-color: #f2f2f2;
}
.box__dragndrop,
.box__icon
{
    display: none;
}
.box.has-advanced-upload .box__dragndrop
{
    display: inline;
}
.box.has-advanced-upload .box__icon
{
    width: 100%;
    height: 80px;
    fill: #92b0b3;
    display: block;
    margin-bottom: 40px;
}

.box.is-uploading .box__input,
.box.is-success .box__input,
.box.is-error .box__input
{
    visibility: hidden;
}

.box__uploading,
.box__success,
.box__error
{
    display: none;
}
.box.is-uploading .box__uploading,
.box.is-success .box__success,
.box.is-error .box__error
{
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    -webkit-transform: translateY( -50% );
    transform: translateY( -50% );
}
.box__uploading
{
    font-style: italic;
}
.box__success
{
    -webkit-animation: appear-from-inside .25s ease-in-out;
    animation: appear-from-inside .25s ease-in-out;
}
@-webkit-keyframes appear-from-inside
{
    from	{ -webkit-transform: translateY( -50% ) scale( 0 ); }
    75%		{ -webkit-transform: translateY( -50% ) scale( 1.1 ); }
    to		{ -webkit-transform: translateY( -50% ) scale( 1 ); }
}
@keyframes appear-from-inside
{
    from	{ transform: translateY( -50% ) scale( 0 ); }
    75%		{ transform: translateY( -50% ) scale( 1.1 ); }
    to		{ transform: translateY( -50% ) scale( 1 ); }
}

.box__restart
{
    font-weight: 700;
}
.box__restart:focus,
.box__restart:hover
{
    color: #39bfd3;
}

.box__file
{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.box__file + label:hover strong,
.box__file:focus + label strong,
.box__file.has-focus + label strong
{
    color: #db1a21;
    cursor: pointer;
    padding: 0;
}
.box__file:focus + label,
.box__file.has-focus + label
{
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
    outline: none;
}
.box__file + label *
{
    /* pointer-events: none; */ /* in case of FastClick lib use */
}

.no-js .box__file + label
{
    display: none;
}

.no-js .box__button
{
    display: block;
}
.box__button
{
    font-weight: 700;
    color: #e5edf1;
    background-color: #39bfd3;
    display: none;
    padding: 8px 16px;
    margin: 40px auto 0;
}
.box__button:hover,
.box__button:focus
{
    background-color: #0f3c4b;
}
