.main-index-jumbo__wrapper {
    position: relative;
    height: 100%;
}


.main-index-jumbo {
    position: relative;
    min-height: 100%;
    color: #ffffff;
    background-color: #db1a21;
}

.main-index-jumbo__lottie-player {
    max-width: 1600px;
    margin-left: auto;
}

.main-index-jumbo__container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.main-index-jumbo__info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 0.75rem;
    padding: 0;
}

@media screen and (min-width: 768px) {
    .main-index-jumbo__wrapper {
        height: 100%;
        min-height: fill-available;
        min-height: -webkit-fill-available;
        min-height: -moz-available;
        padding-bottom: 200px;
    }

    .main-index-jumbo__content {
        position: relative;
        z-index: 99;
    }

    .main-index-jumbo__lottie-player-wrap {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        padding-top: 0;
    }

    .main-index-jumbo__info {
        padding: 0;
    }
}
