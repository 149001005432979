.header {
    padding-top: 71px;
}

.header__publish-button {
    color: #fff;
    text-decoration: none;
}

.header__publish-button:hover {
    color: #ffffff;
}

.header__navbar {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;

    color: #ffffff;
    font-size: 1.2rem;

    background-color: #db1a21;
    transition: background-color 0.5s;
}

.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 117px;
}

.header__logo-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
}

.header__logo {
    transform: scale(0.7);
}

.header__logo-text {
    display: none;
    margin-top: 5px;
}

.header__menu {
    display: none;
    flex-grow: 1;
    flex-wrap: wrap;
    max-width: calc((100% / 2) - 125px);
    margin-top: 91px;
}

.header__mobile-menu {
    position: relative;
    top: 25px;
    display: flex;
    justify-content: center;
    max-height: 0;

    visibility: hidden;
    transition: max-height 0.5s, padding-bottom 0.5s;
}

.header__mobile-menu_show {
    max-height: 500px;
    padding-bottom: 25px;

    visibility: visible;
    transition: max-height 1s;
}

.header__account {
    position: relative;
    display: flex;
    align-items: center;

    color: #ffffff;
    text-decoration: none;
}

.header__account::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    right: -20px;
    display: none;
    background-image: url("/img/icons/chevron-right.svg");
    background-size: cover;
    transition: right 0.5s;
}

.header__account:hover {
    color: #ffffff;
}

.header__account:hover::after {
    right: -25px;
    transition: right 0.5s;
}

.header__link {
    display:inline-block;
    padding: 0 20px 0 0;

    color: #ffffff;
    text-decoration: none;
}

.header__link::after {
    content: '';
    display:block;
    border-bottom: solid 2px #ffffff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}

.header__link:hover {
    color: #ffffff;
}

.header__link:hover::after {
    color: #ffffff;
    transform: scaleX(1);
}

.header__link_active::after {
    transform: scaleX(1);
}

.header_transparent {
    background: linear-gradient(45deg, #f1f3fc, #e1fff1);
    background: -moz-linear-gradient(45deg, #f1f3fc, #e1fff1);
    background: -webkit-linear-gradient(45deg, #f1f3fc, #e1fff1);
    background: -ms-linear-gradient(45deg, #f1f3fc, #e1fff1);

    .header__navbar {
        background-color: transparent;
        transition: background-color 0.5s;
    }

    .header__navbar_aqua {
        background-color: #e1fff1;
    }

    .header__navbar_white {
        background-color: #ffffff;
    }

    .header__navbar_blue {
        background-color: #e5f9ff;
    }

    .header__navbar_pink {
        background-color: #fac0cf;
    }

    .navbar-brand, .header__link, .editus-dropdown__title, .editus-dropdown__link {
        color: #000000;
    }

    .navbar-brand:hover {
        color: #000000;
    }

    .header__link::after {
        border-bottom: solid 2px #000000;
    }

    .header__account::after {
        background-image: url(/img/icons/chevron-right-black.svg);
    }

    .editus-dropdown__btn path {
        stroke: #000000;
    }

    .header__publish-button {
        color: #000000;
    }
}

@media (min-width: 576px) {
    .header__logo-text {
        display: initial;
        margin-left: 10px;
    }
}

@media (min-width: 768px) {
    .header__container {
        justify-content: center;
        align-items: flex-start;
    }

    .header__logo-block {
        position: absolute;
        flex-direction: column;
        margin-top: 16px;
    }

    .header__logo {
        transform: scale(1);
        transition: transform 0.5s;
    }

    .header__logo:hover {
        transform: scale(1.05);
        transition: transform 0.5s;
    }

    .header__logo-text {
        margin-left: 0;
    }

    .header__burger {
        display: none;
    }

    .header__menu {
        display: flex;
    }

    .header__menu_left {
        justify-content: flex-start;
        margin-right: 125px;
    }

    .header__menu_right {
        justify-content: flex-end;
        margin-left: 125px;
    }

    .header__mobile-menu {
        display: none;
    }
}

@media (min-width: 992px) {
    .header__publish-button {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 45px;
        padding: 0.7em;

        color: #db1a21;
        text-decoration: none;
        text-align: center;
        white-space: nowrap;

        border: 2px solid #ffffff;
        background-color: #ffffff;
        border-radius: 50px;

        user-select: none;
    }

    .header__publish-button:hover {
        color: #ffffff;
        border: 2px solid #ffffff;
        background-color: #db1a21;
    }

    .header__publish-button:active {
        transform: scale(1.02);
        transition: transform 0.1s;
    }

    .header__account::after {
        display: block;
    }
    .header_transparent {
        .header__publish-button {
            color: #ffffff;
            border: 2px solid #db1a21;
            background-color: #db1a21;
        }

        .header__publish-button:hover {
            color: #db1a21;
            border: 2px solid #db1a21;
            background-color: transparent;
        }
    }
}

// DROPDOWN

.editus-dropdown {
    position: relative;
}

.editus-dropdown__btn {
    border: none;
    background-color: transparent;
}

.editus-dropdown__btn.collapsed {
    transform: rotate(0deg);
    transition: transform 0.25s;
}

.editus-dropdown__btn:not(.collapsed) {
    transform: rotate(90deg);
    transition: transform 0.25s;
}

.editus-dropdown__list {
    list-style: none;
}

.editus-dropdown__link {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    white-space: nowrap;
}

.editus-dropdown__link:hover {
    color: #db1a21;
}

@media (min-width: 992px) {
    .editus-dropdown {
        position: relative;
    }

    .editus-dropdown__btn {
        display: none;
    }

    .editus-dropdown__list {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        padding: 0;
        padding-top: 1em;

        background-color: transparent;
        border-radius: 0.5em;
    }

    .editus-dropdown__list::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(100% - 1.2em);
        width: 1em;
        height: 1em;

        background-image: url("/img/icons/up-arrow.png");
        background-size: cover;
    }

    .editus-dropdown__list::after {
        content: "";
        position: absolute;
        top: 1em;
        width: 100%;
        height: calc(100% - 1em);

        background-color: #ffffff;
        border-radius: 0.5em;
        box-shadow: 0 5px 9px 0 #464646;
        z-index: -1;
    }

    .editus-dropdown:hover .editus-dropdown__list {
        display: block;
    }

    .editus-dropdown__item:first-child .editus-dropdown__link {
        border-radius: 0.5em 0.5em 0 0;
    }

    .editus-dropdown__item:last-child .editus-dropdown__link {
        border-radius: 0 0 0.5em 0.5em;
    }

    .editus-dropdown__link {
        display: block;
        padding: 1em 2em;
        color: #000000;
        text-decoration: none;
        background-color: #ffffff;

        white-space: nowrap;
    }

    .editus-dropdown__title {
        cursor: pointer;
    }
}

.header_transparent-white .editus-dropdown__title, .header_transparent-white .header__link, .header_transparent-white .navbar-brand, .header_transparent-white .navbar-brand:hover {
    color: #fff
}
.header_transparent-white .editus-dropdown__btn path {
    stroke: #fff !important;
}
.header_transparent-white .header__account:after {
    background-image: url(/img/icons/chevron-right.svg)
}

