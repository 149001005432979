.footer {
    margin-top: 120px;
}

.footer__socials-list {
    display: flex;
}

.footer__socials-item {
    margin-right: 1.5em;
}

.footer__socials-item:last-child {
    margin-right: 0;
}

.footer__socials-link {
    display: block;
    width: 35px;
    height: 35px;

    border-radius: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer__socials-facebook {
    background-image: url("/img/socials/facebook-red.png");
}

.footer__socials-vk {
    background-image: url("/img/socials/vk-red.png");
}

.footer__socials-twitter {
    background-image: url("/img/socials/twitter-red.png");
}

.footer__socials-ok {
    background-image: url("/img/socials/ok-red.png");
}

.footer__socials-youtube {
    background-image: url("/img/socials/youtube-red.png");
}

.footer__socials-zen {
    background-image: url("/img/socials/zen-red.png");
}

.footer__socials-insta {
    background-size: 50%;
    background-color: #db1a21;
    background-image: url("/img/socials/insta.png");
}

.footer__socials-item:hover .footer__socials-facebook {
    background-image: url("/img/socials/facebook.png");
}

.footer__socials-item:hover .footer__socials-vk {
    background-image: url("/img/socials/vk.png");
}

.footer__socials-item:hover .footer__socials-insta {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

.footer__socials-item:hover .footer__socials-insta::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;

    background-image: url("/img/socials/insta.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer__socials-item:hover .footer__socials-twitter {
    background-image: url("/img/socials/twitter.png");
}

.footer__socials-item:hover .footer__socials-ok {
    background-image: url("/img/socials/ok.png");
}

.footer__socials-item:hover .footer__socials-youtube {
    background-image: url("/img/socials/youtube.png");
}

.footer__socials-item:hover .footer__socials-zen {
    background-image: url("/img/socials/zen.png");
}

.footer__nav {
    height: 95%;
    border-left: 1px solid #db1a21;
}

.footer__nav-title {
    margin-bottom: 1em;
    padding-left: 1.2em;

    font-family: inherit;
    font-size: 1.25rem;
    font-weight: bold;
    color: #db1a21;
}

.footer__nav-link {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #808080;
    text-decoration: none;
}

.footer__nav-item {
    margin-bottom: 0.25em;
    padding: 0.25em 0.5em;
    padding-left: 1.5em;

    border-radius: 0 50px 50px 0;
}

.footer__nav-item:hover {
    background-color: #db1a21;
}

.footer__nav-item:hover .footer__nav-link {
    color: #ffffff;
}

.footer__nav-link:hover {
    color: #ffffff;
}

.footer__copyright {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #808080;
}

@media (min-width: 769px) {
    .footer__float-end {
        min-width: 65%;
    }
}
