// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

@import "components/checkbox";
@import "components/header";
@import "components/footer";
@import "components/jumbo";
@import "components/jumbotron";
@import "components/main-index-jumbo";
@import "components/feedback-form";

$red-color: #db1a21;

.mt-6 {
    margin-top: 5rem !important
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Slab', serif;
}

/* СТИЛИ ЯНЫ */

a {
    color: #db1a21;
    text-decoration: none
}

a:hover {
    color: #4d4b4b
}

@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 480px
    }
}

@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 640px
    }
}

@media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 860px
    }
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1020px
    }
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1180px
    }
}

.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #c1c1c1;
    border-radius: 25px;
    display: block;
    font-family: Montserrat, sans-serif;
    padding: .75em 1.5em;
    width: 100%
}

.btn-info {
    background-color: #db1a21;
    border-color: #db1a21;
    color: #fff
}

.rounded-50 {
    border-radius: 3rem !important
}

.api-jumbotron__wrapper {
    background-color: #07143d;
    background-image: url(/img/api/bg.png);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 70%;
    height: auto;
}

.api-steps__box_1:after {
    background-image: url(/img/api/icon_1.svg);
    background-size: 60%
}

.api-steps__box_2:after {
    background-image: url(/img/api/icon_2.svg);
    background-size: 60%
}

.api-steps__box_3:after {
    background-image: url(/img/api/icon_3.svg);
    background-size: 60%
}

@media screen and (min-width: 768px) {
    .bg-api {
        background: url(/img/api/books-api.svg);
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: 50%;
        height: 100%;
        min-height: fill-available;
        min-height: -webkit-fill-available;
        min-height: -moz-available;
        padding-bottom: 120px;
        padding-top: 80px
    }
}

.partner-jumbotron__wrapper {
    background-color: #00a6bf;
    background-image: url(/img/api/bg_partner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}


ol, ul {
    font-family: Montserrat, sans-serif;
}

.img-fluid, .img-thumbnail {
    display: block;
    margin: auto;
}

.navbar-nav {
    font-family: inherit;
    font-size: 1.2rem;
}

p {
    font-family: Montserrat, sans-serif;
}

/* СТИЛИ ЯНЫ */

.button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7em 1em;

    color: #ffffff;
    font-weight: bold;
    border: none;
    border-radius: 50px;
    background-color: #db1a21;
    text-decoration: none;
    text-align: center;

    user-select: none;
}

.button:disabled {
    background-color: #BDBDBD;
    cursor: not-allowed;
}

.button:hover {
    color: #fff;
    background-color: #333;
}

.button:active {
    transform: scale(1.02);
    transition: transform 0.1s;
}

.button_outline {
    border: 2px solid #db1a21;
    color: #db1a21;
    background-color: transparent;
}

.button_outline:hover {
    background-color: #db1a21;
    color: #fff
}

.button_flat {
    padding: 0.2em 1em
}

.button_white {
    color: #ffffff;
    border-color: #ffffff;
}

.button_white:hover {
    color: $red-color;
    background-color: #ffffff;
}

.button_white-full {
    color: $red-color;
    border: 2px solid #ffffff;
    background-color: #ffffff;
}

.button_white-full:hover {
    color: #ffffff;
    background-color: #db1a21;
    border: 2px solid #ffffff;
}

.genre-box {
    box-sizing: border-box;
    display: flex;
    min-height: 200px;
    padding: 20px;

    font-size: 20px;
    color: #ffffff;
    text-decoration: none;
    background-color: #787676;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.art-literature {
    background-image: url("/img/categories/art-literature.webp");
}

.biography {
    background-image: url("/img/categories/biography.webp");
}

.business {
    background-image: url("/img/categories/business.webp");
}

.children {
    background-image: url("/img/categories/children.webp");
}

.fiction {
    background-image: url("/img/categories/fiction.webp");
}

.poetry {
    background-image: url("/img/categories/poetry.webp");
}

.genre-box:hover {
    color: #db1a21;
}

.genre-box_text-bottom {
    align-items: flex-end;
}

.bookstore-btn {
    padding: 0.625rem 1.25rem;
    color: #db1a21;
    text-decoration: none;
    border: 2px solid #db1a21;
    border-radius: 50px;
}

.bookstore-btn:hover {
    color: #ffffff;
    background-color: #db1a21;
}

.link {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    color: $red-color;
}

.link_active {
    color: $red-color;
}

.link_red {
    color: $red-color;
}

.link_red:hover {
    text-decoration: underline;
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif;
}

.font-roboto {
    font-family: Roboto Slab, serif;
}

@media screen and (min-width: 768px) {
    .authors-books-slider {
        padding: 0 75px;
    }

    .authors-book-card__img {
        //object-fit: cover;
        //max-height: 40vh;
    }
}

.girl-reading {
    min-height: 265px;

    color: #ffffff;

    background-color: #f7e8e9;
    background-image: url("/img/girl-reading.webp");
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 1900vw;
}

@media screen and (min-width: 768px) {
    .girl-reading {
        min-height: 525px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .girl-reading__text {
        font-size: 1.25rem
    }
}

.steps img {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-name: img-jump;
}

@keyframes img-jump {
    from {
        transform: translateY(-10px);
    }

    to {
        transform: translateY(10px);
    }
}


.step-text {
    max-width: 330px;
    font-family: 'Montserrat', sans-serif;
}

.step-number {
    font-size: 5em;
    color: #db1a21;
    line-height: 1em;
}

.title-red {
    color: #db1a21;
}

.contact-us-btn {
    max-width: 100%;
    padding: 1em;
    font-size: 1.2em;

    color: #ffffff;
    text-decoration: none;
    text-align: center;

    border: none;
    border-radius: 30px;
    background-color: #db1a21;
}

.contact-us-btn:hover {
    color: #ffffff;
    background-color: #333;
}

.services-list__label {
    position: relative;
    cursor: pointer;
}

.services-list__label:hover .services-list__label-bg {
    border: 1px solid #db1a21;
}

.services-list__label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.services-list__label-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    border-radius: 25px;
    background-color: transparent;
}

.services-list__label input:checked ~ .services-list__label-bg {
    border: 1px solid #db1a21;
    background-color: #fde9ea;
}

.services-list__title {
    margin-top: -2em;
}

.text-proofreading__container_dark-grey {
    background-color: #585858;
}

.text-proofreading__container_light-grey {
    background-color: #dddddd;
}

.text-proofreading__container_pink {
    background-color: #fde9ea;
}

.text-proofreading__container_red {
    background-color: $red-color;
}

.text-proofreading__text-box {
    position: relative;
    padding: 0 0.5em;
    border-radius: 50px;
    background-color: #fde9ea;
    line-height: 20px;
}

.text-proofreading__text-box_quotes::before {
    content: "";
    position: absolute;
    top: 28px;
    left: -57px;
    z-index: 1;
    width: 80px;
    height: 60px;
    background-image: url("/img/services-text/quotes.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.steps-text {
    background-color: $red-color;
}

.steps-text_gray {
    background: linear-gradient(133deg, #5e5e5e 0%, #393939 50%);
}

.steps-text_blue {
    background-color: #134869;
}

.faq__button:not(.collapsed) {
    color: #000000;
    font-weight: bold;
    background-color: transparent;
}

.faq__answer {
    margin: 1em 0;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;

    border-radius: 25px;
    background-color: #f5f4f4;
}

.book-types__tab-content {
    position: relative;
    padding: 40px;
}

.book-types__tab-content::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 30px;
    border-radius: 25px;
    border: 2px solid #db1a21;
    z-index: -1;
}

.book-types__text {
    background-color: #ffffff;
}

.book-types__nav-link {
    color: $red-color;
    text-decoration: underline;
}

.book-types__nav-link:hover {
    color: $red-color;
    text-decoration: none;
}

.book-types__nav-link.active {
    color: #000000 !important;
    font-weight: 500;
    text-decoration: none;
}

@media screen and (min-width: 768px) {
    .book-types__tab-content::after {
        left: 120px;
    }
}

.isbn__container {
    border-radius: 50px;
    background: -moz-linear-gradient(133deg, rgb(94, 94, 94) 0%, rgb(57, 57, 57) 100%);
    background: -webkit-linear-gradient(133deg, rgb(94, 94, 94) 0%, rgb(57, 57, 57) 100%);
    background: -ms-linear-gradient(133deg, rgb(94, 94, 94) 0%, rgb(57, 57, 57) 100%);
    background: linear-gradient(133deg, rgb(94, 94, 94) 0%, rgb(57, 57, 57) 100%);
}

.box-with-shadow {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    margin-bottom: 1.5em;

    border-radius: 25px;
    box-shadow: 0 0 5px 0 #858181;
}

@media screen and (min-width: 768px) {
    .box-with-shadow {

    }
}

.quality-control {
    overflow: hidden;
}

.quality-control__container {
    position: relative;
    padding-left: 2.5em;
    padding-right: 2.5em;

    color: #ffffff;
    background: -moz-linear-gradient(133deg, #559cc9, #134869);
    background: -webkit-linear-gradient(133deg, #559cc9, #134869);
    background: -ms-linear-gradient(133deg, #559cc9, #134869);
    background: linear-gradient(133deg, #559cc9, #134869);
    border-radius: 50px;
}

.quality-control__container::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -35px;
    height: 35%;
    width: 70px;
    border: 2px solid #ffffff;
    border-radius: 0 35px;
}

.quality-control__container::after {
    content: '';
    position: absolute;
    top: 0;
    right: -35px;
    height: 75%;
    width: 70px;
    border: 2px solid #ffffff;
    border-radius: 0 35px;
}

.quality-control__text-strip {
    position: relative;
    padding: 1em 0;
}

.quality-control__text-strip::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2.5em;
    right: -2.5em;

    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
}

.quality-control__video {
    width: 100%;
    border-radius: 50px;
}

.ready-to-print {
    padding: 1em;
    background-color: $red-color;
}

.ready-to-print__container {
    position: relative;
    padding-bottom: 8em;

    border: 2px solid #ffffff;
    border-radius: 25px;
}

.ready-to-print__container::before {
    content: '';
    position: absolute;
    left: 8%;
    bottom: 0;
    width: 30%;
    height: 100%;

    background-image: url("/img/services-print/boy.svg");
    background-repeat: no-repeat;
    background-position-y: bottom;
}

.ready-to-print__container::after {
    content: '';
    position: absolute;
    right: 8%;
    bottom: 0;
    width: 17%;
    height: 90%;

    background-image: url("/img/services-print/girl.svg");
    background-repeat: no-repeat;
    background-position-y: bottom;

}

@media screen and (min-width: 768px) {
    .quality-control__video {
        width: 80%;
        border-radius: 50px;
    }

    .ready-to-print__container {
        padding-bottom: 0;
    }

    .ready-to-print__container::before {
        content: '';
        position: absolute;
        left: 8%;
        bottom: 0;
        width: 13%;
        height: 100%;

        background-image: url("/img/services-print/boy.svg");
        background-repeat: no-repeat;
        background-position-y: bottom;

    }

    .ready-to-print__container::after {
        content: '';
        position: absolute;
        right: 8%;
        bottom: 0;
        width: 8%;
        height: 90%;

        background-image: url("/img/services-print/girl.svg");
        background-repeat: no-repeat;
        background-position-y: bottom;

    }
}

.cover-design__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    margin-bottom: 1.5em;

    background-color: #fde9ea;
}

.cover-design__box_blue {
    background-color: #f0f3fe;
}

.cover-design__box_yellow {
    background-color: #fffaee;
}

.cover-design-any-genre {
    margin-top: 5em;
    padding: 0 1em;
}

.cover-design-any-genre__container {
    position: relative;
    padding: 2em;
}

.cover-design-any-genre__title {
    margin-bottom: 2rem;
    text-align: center;
    color: #ffffff;
}

.cover-design-any-genre__container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 98%;
    height: 100%;
    background-color: #569bca;
    border-radius: 30px;
}

.cover-design-any-genre__container::after {
    content: '';
    position: absolute;
    top: -5%;
    right: 0;
    z-index: -2;

    width: 98%;
    height: 100%;
    background-color: #f4c953;
    border-radius: 30px;
}

.splide-cover-design {
    position: static;
}

.splide-cover-design .splide__arrows {
    position: absolute;
    top: 50%;
    left: 22px;
    right: 30px;
    z-index: 99;

    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.splide-cover-design .splide__arrow {
    width: 30px;
    height: 30px;

    border: none;
    background-color: transparent;
    background-image: url("/img/arrow-right-white.svg");
}

.splide-cover-design .splide__arrow--prev {
    transform: scale(-1);
}

.splide-cover-design__img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 267px;
    height: 370px;
}

.splide-publish {
    position: static;
}

.splide-publish .splide__arrows {
    position: absolute;
    top: 50%;
    left: 22px;
    right: 0;
    z-index: 99;

    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.splide-publish .splide__arrow {
    width: 30px;
    height: 30px;

    border: none;
    background-color: transparent;
    background-image: url("/img/arrow-right.svg");
}

@media screen and (min-width: 768px) {
    .cover-design-any-genre__container {
        padding: 2em 5em;
    }

    .splide-cover-design__img {
        width: 100%;
        max-width: 267px;
        height: 370px;
    }
}

.book-design-cost {
    margin-top: 5em;
    margin-bottom: 4em;
}

.book-design-cost__box {
    display: flex;
    flex-direction: column;
    height: 100%;

    border: 1px solid #858181;
    border-radius: 25px;
    box-shadow: 0 0 5px 0 #858181;
}

.book-design-cost__box:hover {
    box-shadow: 0 0 5px 0 #858181;
}

.book-design-cost__box-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 0;

    border-radius: 25px 25px 0 0;
    background-color: #d70003;
    background-image: url("/img/services-design/price-redbg.svg");
    background-size: cover;
}

.book-design-cost__box-header_blue {
    background-color: #489bff;
    background-image: url("/img/services-design/05.svg");
}

.book-design-cost__box-header_yellow {
    background-color: #f9b52c;
    background-image: url("/img/services-design/061.svg");
}

.book-design-cost__box-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 1.5em;
}

.promo-steps {
    background-color: #f1fafc;
}

.promo-steps__row {
    margin-top: 7em;
}

.promo-steps__col:nth-child(2) {
    margin: 6em 0;
}

.promo-steps__box {
    position: relative;
    height: 100%;
    padding: 5em 1em 3em 1em;

    border: 1px solid #75a5af;
    border-radius: 25px;
}

.promo-steps__box::before {
    content: '';
    position: absolute;
    top: calc(-150px / 2);
    left: 50%;
    transform: translateX(-50%);

    width: 150px;
    height: 150px;

    border: 1px solid #75a5af;
    border-radius: 50%;
}

.promo-steps__box::after {
    content: '';
    position: absolute;
    top: calc(-130px / 2);
    left: 50%;
    transform: translateX(-50%);

    width: 130px;
    height: 130px;

    background-color: #db1a21;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
}

.promo-steps__box_fountain-pen::after {
    background-image: url("/img/services-promo/promo-steps-icon-1.webp");
}

.promo-steps__box_graph::after {
    background-image: url("/img/services-promo/promo-steps-icon-2.webp");
    background-size: 60%;
}

.promo-steps__box_chart::after {
    background-image: url("/img/services-promo/promo-steps-icon-3.png");
    background-size: 60%;
}

.promo-author-fee {
    margin-top: 5em;
}

.promo-author-fee__calculator {
    display: flex;
    padding: 2em 1em;

    background-color: #f3e9fd;
    border-radius: 25px;
}

.promo-author-fee__calculator-col-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-right: 2px solid #adcad5;
}

.promo-author-fee__calculator-col-right {
    width: 100%;
    padding-left: 1.5em;
}

.promo-author-fee__calculate-link {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: #db1a21;
    border-bottom: 1px solid #db1a21;
}

.promo-author-fee__calculate-link:hover {
    color: #db1a21;
}

@media screen and (min-width: 768px) {
    .promo-steps__col:nth-child(2) {
        margin: 0;
    }
}

.promo-book-advertisement {
    margin-top: 5em;
}

.promo-book-advertisement__wrapper {
    background-color: #ecfbf2;
}

.ghost-steps__box_lotus:after {
    background-image: url(/img/services-ghost/icon-lotus.svg);
    background-size: 80%
}

.ghost-steps__box_feather:after {
    background-image: url(/img/services-ghost/icon-feather.svg);
    background-size: 60%
}

.ghost-steps__box_books:after {
    background-image: url(/img/services-ghost/icon-books.svg);
    background-size: 80%
}

.bg-blue {
    background-color: #f1f3fc
}

.bg-yellow {
    background-image: linear-gradient(45deg, #faf5ea, #fff1d1);
}

@media screen and (max-width: 600px) {
    #ghost-steps {
        visibility: hidden;
        display: none;
    }
}

.academy__box {
    display: flex;
    flex-direction: column;
    height: 100%;

    border-radius: 25px;
    border: 1px solid #858181;
}

.academy__box:hover {
    box-shadow: 0 0 5px 0 #858181;
}

.academy__box-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4.5em 0;

    border-radius: 25px 25px 0 0;
    background-size: cover;
}

.academy__box-header_annotation {
    background-image: url("/img/services-text/annotation.webp");
}

.academy__box-header_author-guide {
    background-image: url("/img/services-text/author-guide.jpeg");
}

.academy__box-header_publish-online {
    background-image: url("/img/services-text/publish-online.jpeg");
    background-position: 0 83%;
}

.public-reviews__wrap {
    background-color: #47A9D3FF;
}

.public-reviews__viewport {
    position: relative;
    width: 100%;
    min-height: 60vh;

    background-color: #ffffff;
}

.public-reviews__books {
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translateY(-50%);

    display: flex;
    align-items: flex-end;
    width: 77%;

    background-image: url("/img/services-public/quotes.png");
    background-repeat: no-repeat;
    background-position: 16vw 0vh;
    background-size: 10vw;
}

.public-reviews__book {
    width: 7vw;
    height: calc(19vw / 2);

    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    cursor: pointer;
}

.public-reviews__book input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.public-reviews__book_selected {
    width: 14vw;
    height: 19vw;

    transition: height 0.5s;
}

.public-reviews__book:nth-child(1) {
    background-image: url("/img/services-public/cover1.png");
}

.public-reviews__book:nth-child(2) {
    margin: 0 1em;
    background-image: url("/img/services-public/cover2.jpeg");
}

.public-reviews__book:nth-child(3) {
    background-image: url("/img/services-public/cover3.jpeg");
}

@media screen and (min-width: 991px) {
    .public-reviews__viewport {
        position: relative;
        width: 60%;
    }
}

.certificate-price__box {
    display: flex;
    flex-direction: column;
    height: 100%;

    border-radius: 25px;
    border: 1px solid #858181;
}

.certificate-price__box:hover {
    box-shadow: 0 0 5px 0 #858181;
}

.certificate-price__box-header {
    padding: 1.5em 0;

    color: #ffffff;
    text-align: center;

    border-radius: 25px 25px 0 0;
}

.certificate-price__box-header_red {
    background-color: #ff0000;
}

.certificate-price__box-header_blue {
    background-color: #489bff;
}

.certificate-price__box-header_yellow {
    background-color: #f9b52d;
}

.certificate-price__box-header_green {
    background-color: #349f0a;
}

.certificate-price__box-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    padding: 1em 0 2em 0;
}


.grecaptcha-badge {
    visibility: hidden;
}

// СТИЛИ ЯНЫ

.main-index-jumbo__cursor-text {
    text-shadow: -0.2rem 0 #db1a21, 0 0.2rem #db1a21, 0.2rem 0 #db1a21, 0 -0.2rem #db1a21;
}

.box_pink {
    background-color: #fde9ea;
    border-radius: 25px;
    margin: 3em 0;
    padding: 1.2em 3em 0.4em 3em
}

.bg_books {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url('/img/services-print/bg_books.jpg');
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: .4em 0 1.4em 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 1.8em auto;
}

.alignright {
    float: right;
    margin: .3em 0 2em 2em;
}

.alignleft {
    float: left;
    margin: .3em 2em 2em 0;
}

.aligncenter {
    display: block;
    margin: 1.8em auto;
    clear: both;
}

a img.alignright {
    float: right;
    margin: .3em 0 2em 2em;
}

a img.alignnone {
    margin: 1em 0;
}

a img.alignleft {
    float: left;
    margin: .3em 2em 2em 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.wp-caption {
    color: #777;
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 1.4em 0;
}

.wp-caption.alignleft {
    margin: .3em 1.6em 1em 0;
}

.wp-caption.alignright {
    margin: .3em 0 1em 1.5em;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
}

.wp-caption p.wp-caption-text {
    font-size: .80em;
    margin: 0;
    padding: .2em .4em;
}

.alignleft:first-child, .alignright:first-child {
    margin-top: 1.2em;
}

.maket__box-header {
    align-items: center;
    background-size: cover;
    border-radius: 25px 25px 0 0;
    display: flex;
    justify-content: center;
    padding: 7em 0
}

.maket__box-header_self {
    background-image: url(/img/portfolio/avangard.jpg)
}

.maket__box-header_publish {
    background-image: url(/img/portfolio/cover_26.jpg)
}

.maket__box-header_template {
    background-image: url(/img/portfolio/cover_11.jpg)
}

.card {
    border-radius: 1.5rem;
}

.cite {
    color: grey;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
}

.calculator-wrap {
    background-color: #f7e8e9;
    border-radius: 50px;
    margin-top: 4em;
    padding: 2em
}


.ready-to-print__container:before {
    background-image: url(/img/services-print/boy.svg);
    background-position-y: bottom;
    background-repeat: no-repeat;
    bottom: 0;
    content: "";
    height: 100%;
    left: 8%;
    position: absolute;
    width: 30%
}

.ready-to-print__container:after {
    background-image: url(/img/services-print/girl.svg);
    background-position-y: bottom;
    background-repeat: no-repeat;
    bottom: 0;
    content: "";
    height: 90%;
    position: absolute;
    right: 8%;
    width: 17%
}

@media screen and (min-width: 768px) {
    .quality-control__video {
        border-radius: 50px;
        width: 80%
    }
    .ready-to-print__container {
        padding-bottom: 0
    }
    .ready-to-print__container:before {
        background-image: url(/img/services-print/boy.svg);
        background-position-y: bottom;
        background-repeat: no-repeat;
        bottom: 0;
        content: "";
        height: 100%;
        left: 8%;
        position: absolute;
        width: 13%
    }
    .ready-to-print__container:after {
        background-image: url(/img/services-print/girl.svg);
        background-position-y: bottom;
        background-repeat: no-repeat;
        bottom: 0;
        content: "";
        height: 90%;
        position: absolute;
        right: 8%;
        width: 8%
    }
}

.button_white-full, .button_white:hover {
    background-color: #fff;
    color: #db1a21
}
